import { Component, Input, OnInit } from '@angular/core';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() fixed: boolean = false;

  public mobile_menu: boolean = false;

  public lang_menu: boolean = false;

  public lang_selector: boolean = false;

  constructor(public translate: TranslateService, public func: FunctionsService) { }

  public menu: any = [
    {
      'name': "home",
      'route': '/',
      'href': false
    },

    {
      'name': "posts",
      'route': '/posts',
      'href': false
    }
  ]


  ngOnInit(): void {

    if (this.fixed) {
      var scrollPos = 0;
      window.addEventListener('scroll', function () {
        if (window.scrollY > 500 && window.scrollY < scrollPos) {
          document.getElementById('fixed_appbar').classList.add('show');
          document.getElementById('fixed_appbar').classList.remove('hide');
        } else {
          document.getElementById('fixed_appbar').classList.add('hide');
          document.getElementById('fixed_appbar').classList.remove('show');
        }
        scrollPos = window.scrollY;
      });
    }

  }

  toggleNav() {

    let result = !this.mobile_menu;

    this.mobile_menu = result;

    if (!this.mobile_menu) {
      this.lang_selector = false;
    }

    result ? document.body.style.overflow = "hidden" : document.body.style.overflow = "";

  }

  changeTranslate(translate: string) {
    localStorage.setItem("lang", translate);
    this.lang_selector = false;
  }

}
