<div class="row">
    <div class="col">
        <a href="mailto://support@dastsoftware.com"><h3 class="color-first"><i class="fas fa-envelope"></i> Email</h3></a>
        <small>24/7</small>
    </div>
    <div class="col">
        <a href="https://t.me/dastsoftware" target="_blank"><h3 class="color-first"><i class="fab fa-telegram"></i> Telegram</h3></a>
        <small>24/7</small>
    </div>
</div>
<hr>
<form>
    <label for="name">{{translate.translate("name")}}</label>
    <input #name name="name" class="form-control"><br>

    <label for="email">{{translate.translate("email")}}</label>
    <input #email type="email" name="email" class="form-control"><br>

    <label for="subject">{{translate.translate("subject")}}</label>
    <input #subject name="subject" class="form-control"><br>

    <label for="message">{{translate.translate("message")}}</label>
    <textarea #message name="message" class="form-control"></textarea><br>

    <re-captcha (resolved)="rslv=true" siteKey="6Lf_yicbAAAAABRvCabfkqPzoB9gnCnd7xpqYsOq"></re-captcha><br>

    <button type="button" (click)="submitForm(name.value,email.value,subject.value,message.value)" class="btn btn-lg btn-primary w-100 text-white">{{translate.translate("send")}}</button>

    <p *ngIf="error">{{error}}</p>

</form>