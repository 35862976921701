import { Injectable } from '@angular/core';
import * as Translate from './translate.json';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  constructor() { }

  getFull() {
    console.log(Translate);
  }

  translate(module:string) {

    let lang = "en";

    if (localStorage.getItem("lang")) {
      lang = localStorage.getItem("lang");
    } else {
      lang = navigator.language.substr(0,2);
      localStorage.setItem("lang",lang)
    }

    if (Translate["default"][lang][module]) {
      return Translate["default"][lang][module];
    } else {
      return false;
    }

  }

  getTranslates() {
    return Translate["default"];
  }

  getLang() {
    let lang = "en";
    if (localStorage.getItem("lang")) {
      lang = localStorage.getItem("lang");
    } else {
      lang = navigator.language.substr(0,2);
      localStorage.setItem("lang",lang)
    }
    return lang;
  }

}
