<div class="row">
    <div class="col-4" *ngFor="let p of posts">
        <a routerLink="/post/{{p.slug}}">
            <div class="card shadow rounded mb-5">
                <img *ngIf="p.featured_image_url" class="card-img-top" [src]="p.featured_image_url">
                <div class="card-body">
                    <h5 class="card-title text-center">{{p.title}}</h5>
                    <p class="text-center"><i class="fas fa-clock"></i> {{getCoolDate(p.updated_at)}}</p>
                    <p class="text-center color-first"><i class="fas fa-user-tie"></i> {{p.author}}</p>
                </div>
            </div>
        </a>
    </div>
</div>
<div class="row">
    <div class="col ml-auto mr-auto d-flex">
        <button *ngIf="!loading&&hasMore" (click)="loadPosts()"
            class="btn btn-light text-center ml-auto mr-auto">{{translate.translate('more_posts')}}</button>
        <button *ngIf="loading&&hasMore" (click)="loadPosts()" class="btn btn-light text-center ml-auto mr-auto">
            <div class="spinner-border spinner-border-sm text-light" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </button>
    </div>
</div>
<div *ngIf="checkLang()"></div>