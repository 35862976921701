import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {

  erroroptions: AnimationOptions = {
    path: 'https://assets6.lottiefiles.com/private_files/lf30_3X1oGR.json'
  };

  constructor(public translate:TranslateService,public func:FunctionsService) { }

  ngOnInit(): void {
  }

}
