import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EndpointService } from 'src/app/services/endpoint.service';

import moment from 'moment';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { setSyntheticLeadingComments } from 'typescript';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  public url: string;

  public author: string;
  public title: string;
  public body: any;
  public date: string;
  public preloader: boolean = false;
  public lang: string;
  public featured: string;

  constructor(private api: EndpointService,
    private router: Router,
    public func: FunctionsService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url.replace("/post/", "");
      }
    });
  }

  async ngOnInit() {
    this.lang = this.translate.getLang();
    await this.api.get("/post/slug/" + this.url + "/" + this.lang, {}, true).toPromise().then(res => {
      if (res) {
        this.author = res.author;
        this.title = res.title;
        this.date = res.updated_at;
        this.featured = res.featured_image_url;
        this.body = JSON.parse(res.body);
      } else {
        this.router.navigate(["/" + this.url]);
      }
    });

    /*document.querySelector('meta[name="og:title"]').setAttribute("content", this.title);
    document.title = this.title;
    document.querySelector('meta[name="og:description"]').setAttribute("content", this.getFirstContent());
    document.querySelector('meta[name="description"]').setAttribute("content", this.getFirstContent());
    if (this.featured) {
      document.querySelector('meta[property="og:image"]').setAttribute("content", this.featured);
    } */

  }

  getFirstContent() {
    let result: string = "";
    for (let i = 0; i < this.body.length; i++) {
      if (!result) {
        if (this.body[i].type == 'paragraph') {
          result = this.body[i].data.text.length > 128 ? this.body[i].data.text.substring(0, 128) + "..." : this.body[i].data.text;
        }
      }
    }
    return result;
  }

  getCoolDate(date) {
    moment.locale(this.translate.getLang());
    return moment(date).fromNow();
  }

  private checker: boolean = false;

  async checkLang() {
    if (this.checker) { return; }
    if (localStorage.getItem("lang") !== this.lang) {
      this.checker = true;
      this.lang = localStorage.getItem("lang");
      await this.api.get("/post/slug/" + this.url + "/" + this.lang, {}, true).toPromise().then(res => {
        this.checker = false;
        if (res) {
          this.author = res.author;
          this.title = res.title;
          this.date = res.updated_at;
          this.body = JSON.parse(res.body);
        } else {
          this.router.navigate(["/" + this.url]);
        }
      });
    }
  }

}
