import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor() { }


  isMobile() {
    return window.innerWidth < 770;
  }

  isDesktop() {
    return window.innerWidth > 1200;
  }

  getResolution() {
    return window.innerWidth;
  }

}
