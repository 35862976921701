import { Component, OnInit } from '@angular/core';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss']
})
export class CallComponent implements OnInit {

  public sending:boolean = false;
  public error:any = false;
  public cpt:boolean = false;

  constructor(public translate:TranslateService,private api:EndpointService) { }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    this.cpt = true;
  }

  callme(number:any) {
    if (this.checkMobile(number)) {
      this.error = false;
      if (number) {
        this.sending = true;
        this.api.post("/cform.php",{"callus":"1","phone":number}).toPromise().then(res=>{
        }).catch(e=>{
          //console.log("ERROR ",e);
        });
      } else {
        this.error = this.translate.translate("required_fields");
      }
    } else {
      this.error = this.translate.translate("phone_error");
    }
  }

  checkMobile(mobile:any) {

    if (mobile[0] != "+") {
      return false;
    }

    let exp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return exp.test(String(mobile));
  }

}
