import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EndpointService } from 'src/app/services/endpoint.service';

import { SocialAuthService, SocialUser } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public username: string;
  public password: string;
  public sha: string;
  public error: string;
  public title: string = "Dashboard";
  public screen: string = "/login";
  public date: number = new Date().getFullYear();

  public menu: any = [
    {
      "icon": "home",
      "title": "Dashboard",
      "url": "/ds_admin/dashboard"
    },
    {
      "icon": "list",
      "title": "Posts",
      "url": "/ds_admin/posts"
    },
    {
      "icon": "sign-out-alt",
      "title": "Logout",
      "url": "/ds_admin/logout"
    },
  ];

  public pages: any = [
    {
      "url": "/dashboard",
      "title": "Dashboard"
    },
    {
      "url": "/posts",
      "title": "Posts"
    },
    {
      "url": "/post/edit",
      "title": "Edit post"
    },
    {
      "url": "/post/create",
      "title": "Create post"
    },
  ]

  user: SocialUser;

  public jwt: string = localStorage.getItem("jwt");

  constructor(private api: EndpointService, private router: Router, private authService: SocialAuthService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.screen = val.url;
      }
    });
  }

  ngOnInit(): void {

    if (this.jwt) {

      if (this.screen === "/ds_admin/login" || this.screen === "/ds_admin/login/") {
        this.router.navigate(["/ds_admin/dashboard"]);
      }

      if (this.screen === "/ds_admin" || this.screen === "/ds_admin/") {
        this.router.navigate(["/ds_admin/dashboard"]);
      }

      for (let m = 0; m < this.pages.length; m++) {
        if (this.contains(this.pages[m].url)) {
          this.title = this.pages[m].title;
        }
      }

    } else {
      this.router.navigate(["/ds_admin/login"]);

      this.authService.authState.subscribe((user) => {
        this.api.post("/user", {
          "social_auth_jwt": user.idToken
        }, {}, true).subscribe(res => {
          localStorage.setItem("jwt", res["access_token"]);
          this.router.navigate(["/ds_admin/dashboard"]);
          if (res["statusCode"] != 200) {
            this.error = "Invalid account";
          }
        });
      });
    }

  }

  async signInWithGoogle() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  contains(content: string) {
    return this.screen.includes(content);
  }

}
