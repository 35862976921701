<!-- English -->
<div *ngIf="translate.getLang()=='en'">
    <p>At Dast, accessible from dast.software, one of our main priorities is the privacy of our visitors. This Privacy
        Policy document contains types of information that is collected and recorded by Dast and how we use it.</p>

    <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact
        us.</p>

    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards
        to the information that they shared and/or collect in Dast. This policy is not applicable to any information
        collected offline or via channels other than this website.</p>

    <h2>Consent</h2>

    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

    <h2>Information we collect</h2>

    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be
        made clear to you at the point we ask you to provide your personal information.</p>
    <p>If you contact us directly, we may receive additional information about you such as your name, email address,
        phone number, the contents of the message and/or attachments you may send us, and any other information you may
        choose to provide.</p>
    <p>When you register for an Account, we may ask for your contact information, including items such as name, company
        name, address, email address, and telephone number.</p>

    <h2>How we use your information</h2>

    <p>We use the information we collect in various ways, including to:</p>

    <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
        <li>Communicate with you, either directly or through one of our partners, including for customer service, to
            provide you with updates and other information relating to the website, and for marketing and promotional
            purposes</li>
        <li>Send you emails</li>
        <li>Find and prevent fraud</li>
    </ul>

    <h2>Log Files</h2>

    <p>Dast follows a standard procedure of using log files. These files log visitors when they visit websites. All
        hosting companies do this and a part of hosting services' analytics. The information collected by log files
        include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp,
        referring/exit pages, and possibly the number of clicks. These are not linked to any information that is
        personally identifiable. The purpose of the information is for analyzing trends, administering the site,
        tracking users' movement on the website, and gathering demographic information.</p>

    <h2>Cookies and Web Beacons</h2>

    <p>Like any other website, Dast uses 'cookies'. These cookies are used to store information including visitors'
        preferences, and the pages on the website that the visitor accessed or visited. The information is used to
        optimize the users' experience by customizing our web page content based on visitors' browser type and/or other
        information.</p>

    <p>For more general information on cookies, please read <a class="color-first"
            href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank">"What Are Cookies"</a>.</p>



    <h2>Advertising Partners Privacy Policies</h2>

    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of Dast.</p>

    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in
        their respective advertisements and links that appear on Dast, which are sent directly to users' browser. They
        automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness
        of their advertising campaigns and/or to personalize the advertising content that you see on websites that you
        visit.</p>

    <p>Note that Dast has no access to or control over these cookies that are used by third-party advertisers.</p>

    <h2>Third Party Privacy Policies</h2>

    <p>Dast's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the
        respective Privacy Policies of these third-party ad servers for more detailed information. It may include their
        practices and instructions about how to opt-out of certain options. </p>

    <p>You can choose to disable cookies through your individual browser options. To know more detailed information
        about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

    <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
    <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of
        personal data that a business has collected about consumers.</p>
    <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
    <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
        please contact us.</p>

    <h2>GDPR Data Protection Rights</h2>

    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to
        the following:</p>
    <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee
        for this service.</p>
    <p>The right to rectification – You have the right to request that we correct any information you believe is
        inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
    <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
    </p>
    <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal
        data, under certain conditions.</p>
    <p>The right to object to processing – You have the right to object to our processing of your personal data, under
        certain conditions.</p>
    <p>The right to data portability – You have the right to request that we transfer the data that we have collected to
        another organization, or directly to you, under certain conditions.</p>
    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights,
        please contact us.</p>

    <h2>Children's Information</h2>

    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and
        guardians to observe, participate in, and/or monitor and guide their online activity.</p>

    <p>Dast does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you
        think that your child provided this kind of information on our website, we strongly encourage you to contact us
        immediately and we will do our best efforts to promptly remove such information from our records.</p>
</div>
<!-- Spanish -->
<div *ngIf="translate.getLang()=='es'">
    <div _ngcontent-osr-c111="">
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">En Dast, accesible desde dast.software, una de nuestras
                    principales prioridades es la privacidad de nuestros visitantes. </font>
                <font style="vertical-align: inherit;">Este documento de Política de privacidad contiene tipos de
                    información recopilada y registrada por Dast y cómo la usamos.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si tiene preguntas adicionales o necesita más información sobre
                    nuestra Política de privacidad, no dude en contactarnos.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Esta Política de privacidad se aplica solo a nuestras actividades
                    en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que
                    compartieron y / o recopilaron en Dast. </font>
                <font style="vertical-align: inherit;">Esta política no se aplica a ninguna información recopilada fuera
                    de línea o a través de canales que no sean este sitio web.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Consentimiento</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;" class="">Al utilizar nuestro sitio web, usted acepta nuestra
                    Política de privacidad y acepta sus términos.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Información que recopilamos</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">La información personal que se le solicita que proporcione y las
                    razones por las que se le solicita que la proporcione se le aclararán en el momento en que le
                    solicitemos que proporcione su información personal.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si se comunica con nosotros directamente, es posible que
                    recibamos información adicional sobre usted, como su nombre, dirección de correo electrónico, número
                    de teléfono, el contenido del mensaje y / o los archivos adjuntos que pueda enviarnos, y cualquier
                    otra información que elija proporcionar.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Cuando se registra para obtener una cuenta, podemos solicitar su
                    información de contacto, incluidos elementos como el nombre, el nombre de la empresa, la dirección,
                    la dirección de correo electrónico y el número de teléfono.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Cómo usamos su información</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Usamos la información que recopilamos de varias maneras, incluso
                    para:</font>
            </font>
        </p>
        <ul _ngcontent-osr-c111="">
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Proporcionar, operar y mantener nuestro sitio web.</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Mejorar, personalizar y ampliar nuestro sitio web</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Comprender y analizar cómo utiliza nuestro sitio web.</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Desarrollar nuevos productos, servicios, características y
                        funcionalidades.</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Comunicarse con usted, ya sea directamente o a través de uno
                        de nuestros socios, incluso para el servicio al cliente, para proporcionarle actualizaciones y
                        otra información relacionada con el sitio web, y con fines promocionales y de marketing.</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Enviarte correos electrónicos</font>
                </font>
            </li>
            <li _ngcontent-osr-c111="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Encuentre y evite el fraude</font>
                </font>
            </li>
        </ul>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Archivos de registro</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Dast sigue un procedimiento estándar de uso de archivos de
                    registro. </font>
                <font style="vertical-align: inherit;">Estos archivos registran a los visitantes cuando visitan sitios
                    web. </font>
                <font style="vertical-align: inherit;">Todas las empresas de alojamiento hacen esto y forman parte del
                    análisis de los servicios de alojamiento. </font>
                <font style="vertical-align: inherit;">La información recopilada por los archivos de registro incluye
                    direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet
                    (ISP), sello de fecha y hora, páginas de referencia / salida y posiblemente el número de clics.
                </font>
                <font style="vertical-align: inherit;">Estos no están vinculados a ninguna información que sea de
                    identificación personal. </font>
                <font style="vertical-align: inherit;">El propósito de la información es analizar tendencias,
                    administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información
                    demográfica.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Cookies y balizas web</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Como cualquier otro sitio web, Dast utiliza "cookies". </font>
                <font style="vertical-align: inherit;">Estas cookies se utilizan para almacenar información, incluidas
                    las preferencias de los visitantes y las páginas del sitio web a las que el visitante accedió o
                    visitó. </font>
                <font style="vertical-align: inherit;">La información se utiliza para optimizar la experiencia de los
                    usuarios al personalizar el contenido de nuestra página web en función del tipo de navegador de los
                    visitantes y / u otra información.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Para obtener más información general sobre las cookies, lea
                </font>
            </font><a _ngcontent-osr-c111="" href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank"
                class="color-first">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">"Qué son las cookies"</font>
                </font>
            </a>
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;"> .</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Políticas de privacidad de socios publicitarios</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Puede consultar esta lista para encontrar la Política de
                    privacidad de cada uno de los socios publicitarios de Dast.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Los servidores de anuncios de terceros o las redes de anuncios
                    utilizan tecnologías como cookies, JavaScript o Web Beacons que se utilizan en sus respectivos
                    anuncios y enlaces que aparecen en Dast, que se envían directamente al navegador de los usuarios.
                </font>
                <font style="vertical-align: inherit;">Reciben automáticamente su dirección IP cuando esto ocurre.
                </font>
                <font style="vertical-align: inherit;">Estas tecnologías se utilizan para medir la efectividad de sus
                    campañas publicitarias y / o para personalizar el contenido publicitario que ve en los sitios web
                    que visita.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Tenga en cuenta que Dast no tiene acceso ni control sobre estas
                    cookies que utilizan los anunciantes de terceros.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Políticas de privacidad de terceros</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">La Política de privacidad de Dast no se aplica a otros
                    anunciantes o sitios web. </font>
                <font style="vertical-align: inherit;">Por lo tanto, le recomendamos que consulte las respectivas
                    Políticas de Privacidad de estos servidores de anuncios de terceros para obtener información más
                    detallada. </font>
                <font style="vertical-align: inherit;">Puede incluir sus prácticas e instrucciones sobre cómo excluirse
                    de determinadas opciones.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Puede optar por deshabilitar las cookies a través de las opciones
                    de su navegador individual. </font>
                <font style="vertical-align: inherit;">Para conocer información más detallada sobre la gestión de
                    cookies con navegadores web específicos, se puede encontrar en los respectivos sitios web de los
                    navegadores.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Derechos de privacidad de la CCPA (no vender mi información
                    personal)</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Según la CCPA, entre otros derechos, los consumidores de
                    California tienen derecho a:</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Solicite que una empresa que recopila los datos personales de un
                    consumidor divulgue las categorías y piezas específicas de datos personales que una empresa ha
                    recopilado sobre los consumidores.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Solicitar que una empresa elimine cualquier dato personal sobre
                    el consumidor que haya recopilado una empresa.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Solicitar que una empresa que vende los datos personales de un
                    consumidor no venda los datos personales del consumidor.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si realiza una solicitud, tenemos un mes para responderle.
                </font>
                <font style="vertical-align: inherit;">Si desea ejercer alguno de estos derechos, comuníquese con
                    nosotros.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Derechos de protección de datos del RGPD</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Nos gustaría asegurarnos de que conoce todos sus derechos de
                    protección de datos. </font>
                <font style="vertical-align: inherit;">Todo usuario tiene derecho a lo siguiente:</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El derecho de acceso: tiene derecho a solicitar copias de sus
                    datos personales. </font>
                <font style="vertical-align: inherit;">Es posible que le cobremos una pequeña tarifa por este servicio.
                </font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El derecho a la rectificación: tiene derecho a solicitar que
                    corrijamos cualquier información que crea que es inexacta. </font>
                <font style="vertical-align: inherit;">También tiene derecho a solicitar que completemos la información
                    que cree que está incompleta.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El derecho a borrar: tiene derecho a solicitar que borremos sus
                    datos personales, bajo ciertas condiciones. </font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;" class="">El derecho a restringir el procesamiento: tiene derecho
                    a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones.
                </font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El derecho a oponerse al procesamiento: tiene derecho a oponerse
                    a nuestro procesamiento de sus datos personales, bajo ciertas condiciones.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El derecho a la portabilidad de datos: tiene derecho a solicitar
                    que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, bajo
                    ciertas condiciones.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si realiza una solicitud, tenemos un mes para responderle.
                </font>
                <font style="vertical-align: inherit;">Si desea ejercer alguno de estos derechos, comuníquese con
                    nosotros.</font>
            </font>
        </p>
        <h2 _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Información para niños</font>
            </font>
        </h2>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Otra parte de nuestra prioridad es agregar protección para los
                    niños mientras usan Internet. </font>
                <font style="vertical-align: inherit;">Alentamos a los padres y tutores a observar, participar y / o
                    monitorear y guiar su actividad en línea.</font>
            </font>
        </p>
        <p _ngcontent-osr-c111="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Dast no recopila a sabiendas ninguna Información de
                    identificación personal de niños menores de 13 años. Si cree que su hijo proporcionó este tipo de
                    información en nuestro sitio web, le recomendamos encarecidamente que se comunique con nosotros de
                    inmediato y haremos todo lo posible para eliminarlo de inmediato. dicha información de nuestros
                    registros.</font>
            </font>
        </p>
    </div>
</div>