<app-preloader *ngIf="preloader"></app-preloader>
<div class="bg-primary text-white hero {{!func.isDesktop()?'h-100':''}}">
    <app-header></app-header>
    <app-header [fixed]="true"></app-header>
    <div class="spacing"></div>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center" data-aos="fade-down" data-aos-delay="500">{{title}}</h1>
                <p class="text-center" data-aos="fade-down" data-aos-delay="500"><i class="fas fa-clock"></i>
                    {{getCoolDate(date)}}</p>
                <p class="text-center" data-aos="fade-down" data-aos-delay="500"><i class="fas fa-user-tie"></i>
                    {{author}}</p>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <div class="spacing-mini"></div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card rounded shadow-lg mt--100 p-4" data-aos="fade-up" data-aos-delay="500">

                <div *ngFor="let b of body">
                    <p *ngIf="b.type=='paragraph'" [innerHTML]="b.data.text"></p>
                    <h3 *ngIf="b.type=='header'" [innerHTML]="b.data.text"></h3>
                    <pre *ngIf="b.type=='code'"><code [highlight]="b.data.code"></code></pre>
                    <ol *ngIf="b.type=='list'&&b.data.style=='ordered'">
                        <li *ngFor="let i of b.data.items">
                            <div [innerHTML]="i.content"></div>
                            <ol *ngIf="i.items">
                                <li *ngFor="let items of i.items">
                                    <div [innerHTML]="items.content"></div>
                                    <ol *ngIf="items.items">
                                        <li *ngFor="let _items of items.items">
                                            <div [innerHTML]="_items.content"></div>
                                            <ol *ngIf="_items.items">
                                                <li *ngFor="let _i of _items.items">
                                                    <div [innerHTML]="_i.content"></div>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <ul *ngIf="b.type=='list'&&b.data.style=='unordered'">
                        <li *ngFor="let i of b.data.items">
                            <div [innerHTML]="i.content"></div>
                            <ul *ngIf="i.items">
                                <li *ngFor="let items of i.items">
                                    <div [innerHTML]="items.content"></div>
                                    <ul *ngIf="items.items">
                                        <li *ngFor="let _items of items.items">
                                            <div [innerHTML]="_items.content"></div>
                                            <ul *ngIf="_items.items">
                                                <li *ngFor="let _i of _items.items">
                                                    <div [innerHTML]="_i.content"></div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <img *ngIf="b.type=='image'" style="max-width: 100%;" [src]="b.data.file.url">
                    <blockquote *ngIf="b.type=='quote'">{{b.data.text}}</blockquote>
                    <a *ngIf="b.type=='attaches'" [href]="b.data.file.url" target="_blank" download><button
                            class="btn btn-light"><i class="fas fa-download"></i> {{b.data.title}}</button></a>
                    <table class="table table-hover" *ngIf="b.type=='table'">
                        <tr *ngFor="let t of b.data.content">
                            <td *ngFor="let c of t">{{c}}</td>
                        </tr>
                    </table>
                    <small *ngIf="b.data.caption">{{b.data.caption}}</small>
                    <br><br>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="spacing"></div>
<div *ngIf="checkLang()"></div>