<div class="hero bg-primary">
    <div class="row">
        <div class="col text-center ml-auto mr-auto">
            <app-header></app-header>
            <app-header [fixed]="true"></app-header>
            <div class="spacing"></div>
            <div class="{{func.isMobile()?'':'w-25'}} text-center ml-auto mr-auto">
                <ng-lottie [options]="erroroptions"></ng-lottie>
                <div class="spacing"></div>
                <h1 class="text-white">Error 404</h1>
                <p class="text-white">{{translate.translate("error")}}</p>
            </div>
            <div class="spacing"></div>
        </div>
    </div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#331654" fill-opacity="1" d="M0,288L1440,96L1440,0L0,0Z"></path>
</svg>