import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { EndpointService } from 'src/app/services/endpoint.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit {

  public preloader:boolean = false;
  public anim2:boolean = false;
  public anim3:boolean = false;

  optionsbubble: AnimationOptions = {
    path: 'assets/json/lf30_editor_pj1ryc5q.json'
  };

  element1: AnimationOptions = {
    path: 'https://assets9.lottiefiles.com/packages/lf20_CZ1Za7.json'
  };

  element2: AnimationOptions = {
    path: 'https://assets9.lottiefiles.com/packages/lf20_7fe5oU.json'
  };

  element3: AnimationOptions = {
    path: 'https://assets9.lottiefiles.com/packages/lf20_JbiDg7.json'
  };

  element4: AnimationOptions = {
    path: 'https://assets9.lottiefiles.com/packages/lf20_94KptK.json'
  };
  
  public rslv:boolean = false;
  public error:any = false;

  constructor(public func:FunctionsService,public translate:TranslateService,private api:EndpointService) { }

  ngOnInit(): void {

    setTimeout(() => {
      this.anim2 = true;
    }, 100);

    setTimeout(() => {
      this.anim3 = true;
    }, 200);

  }

  resolved(event) {
    this.rslv = true;
  }

  sendRequest(name,email,othernames,live,pname,about) {
    if (this.rslv) {
      if (name.length>0&&email.length>0&&live.length>0) {
        this.api.post("/cform.php",{"work_with_us":"1","name":name,"email":email,"othernames":othernames,"live":live,"pname":pname,"about":about}).toPromise().then(res=>{
        }).catch(e=>{
          console.log("ERROR ",e);
        });
        this.error = this.translate.translate("thanks");
      } else {
        this.error = this.translate.translate("required_fields");
      }
    } else {
      this.error = "Google captcha error";
    }
  }

}
