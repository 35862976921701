import { Component, OnInit } from '@angular/core';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public rslv:boolean = false;
  public error:any = false;

  constructor(public translate:TranslateService,private api:EndpointService) { }

  ngOnInit(): void {
  }

  submitForm(name:string,email:string,subject:string,message:string) {
    if (this.rslv) {
      if (name.length>0&&email.length>0&&subject.length>0&&message.length>0) {
        this.api.post("/cform.php",{"contact_form":"1","name":name,"email":email,"subject":subject,"message":message}).toPromise().then(res=>{
        }).catch(e=>{
          console.log("ERROR ",e);
        });
        this.error = this.translate.translate("thanks");
      } else {
        this.error = this.translate.translate("required_fields");
      }
    } else {
      this.error = "Google captcha error";
    }
  }

}
