<app-preloader *ngIf="preloader"></app-preloader>
<div class="bg-primary text-white hero {{!func.isDesktop()?'h-100':''}}">
    <app-header></app-header>
    <app-header [fixed]="true"></app-header>
    <div class="spacing"></div>
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center" data-aos="fade-down" data-aos-delay="500">{{getCorrectString()}}</h1>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <div class="spacing-mini"></div>
</div>
<div class="container">
    <div class="row">
        <div class="col">
            <div class="card rounded-lg shadow-lg mt--100 p-4" data-aos="fade-up" data-aos-delay="500">
                <app-contact *ngIf="page=='/contact'"></app-contact>
                <app-cookies *ngIf="page=='/legal/cookies-policy'"></app-cookies>
                <app-job *ngIf="page=='/job'"></app-job>
                <app-privacy *ngIf="page=='/legal/privacy-policy'"></app-privacy>
                <app-terms *ngIf="page=='/legal/terms'"></app-terms>
                <app-about *ngIf="page=='/about'"></app-about>
                <app-order *ngIf="page=='/order'"></app-order>
                <app-unsubscribe *ngIf="page=='/unsubscribe'"></app-unsubscribe>
                <app-posts *ngIf="page=='/posts'"></app-posts>
            </div>
        </div>
    </div>
</div>
<div class="spacing"></div>