import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit {

  public posts:any;
  public page:number = 1;
  public lang:string;
  public loading:boolean = false;
  public hasMore:boolean = true;

  constructor(private api:EndpointService,public translate:TranslateService) { }

  ngOnInit(): void {
    this.lang = this.translate.getLang();
    this.api.get("/post?page="+this.page+"&lang="+this.lang,{},true).toPromise().then(res=>{
      if (res) {
        this.posts = res.data;
        this.hasMore = res.hasNext;
      }
    });
  }

  getCoolDate(date) {
    moment.locale(this.translate.getLang());
    return moment(date).fromNow();
  }

  loadPosts() {
    this.loading = true;
    this.page++;
    this.api.get("/post?page="+this.page+"&lang="+this.lang,{},true).toPromise().then(res=>{
      this.loading = false;
      if (res) {
        console.log("res",res);
        this.hasMore = res.hasNext;
        for (let i = 0; i < res.data.length; i++) {
          this.posts.push(res.data[i]);
          console.log("data",res.data[i])
        }
      }
    });
  }

  private checker:boolean = false;

  async checkLang() {
    if (this.checker) {return;}
    if (localStorage.getItem("lang") !== this.lang) {
      this.page = 1;
      this.checker = true;
      this.lang = localStorage.getItem("lang");
      await this.api.get("/post?page="+this.page+"&lang="+this.lang,{},true).toPromise().then(res=>{
        if (res) {
          this.posts = res.data;
          this.checker = false;
          this.hasMore = res.hasNext;
        }
      });
    }
  }

}
