<div id="{{fixed?'fixed_appbar':'appbar'}}"
    class="{{fixed?'fixed_appbar':'container'}} hide {{mobile_menu&&fixed?'transparent':''}}">
    <div class="row {{fixed?'p-4':'p-5'}}">
        <div class="col mt-auto mb-auto {{fixed?'text-center':''}}">
            <a routerLink="/"><img ngbTooltip="#SoftwareAsAFuture" placement="bottom"
                    class="{{fixed?'logo_2':'logo'}} shrink pointer {{fixed?'ml-auto mr-auto text-center':''}} {{fixed&&mobile_menu?'hide-mobile':''}}"
                    src="{{fixed?'assets/img/blacklogo.png':'assets/img/logo.png'}}"></a>
        </div>
        <div (click)="toggleNav()"
            class="col pointer show-mobile mt-auto mb-auto text-center {{mobile_menu?'change':''}}">
            <div class="bar1 {{fixed&&!mobile_menu?'bg-black':''}}"></div>
            <div class="bar2 {{fixed&&!mobile_menu?'bg-black':''}}"></div>
            <div class="bar3 {{fixed&&!mobile_menu?'bg-black':''}}"></div>
        </div>
        <div class="col-9 h5 mt-auto mb-auto text-center hide-mobile">
            <div class="{{func.isMobile()?'':'ib'}} {{fixed?'item_2':'item'}}" *ngFor="let m of menu">
                <a *ngIf="m.href" class="{{fixed?'item_2':'item'}}" [href]="m.route">{{translate.translate(m.name)}}</a>
                <a *ngIf="!m.href" class="{{fixed?'item_2':'item'}}" routerLink="{{m.route}}">{{translate.translate(m.name)}}</a>
            </div>
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-light" id="dropdownBasic1"
                    ngbDropdownToggle>{{translate.translate(translate.getLang())}}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button *ngFor="let t of translate.getTranslates() | keyvalue" (click)="changeTranslate(t.key)"
                        ngbDropdownItem>{{translate.translate(t.key)}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile_menu bg-primary {{mobile_menu?'mobile_menu_show':'mobile_menu_hide'}}">
    <div>
        <div class="relative text-center ml-auto mr-auto"
            *ngFor="let m of menu">
            <a (click)="toggleNav()" *ngIf="m.href" class="item" [href]="m.route">{{translate.translate(m.name)}}</a>
            <a (click)="toggleNav()" *ngIf="!m.href" class="item" routerLink="{{m.route}}">{{translate.translate(m.name)}}</a>
        </div>
        <div class="relative text-center ml-auto mr-auto">
            <a (click)="lang_selector=true" class="item">{{translate.translate("select-language")}}</a>
        </div>
    </div>
</div>

<div *ngIf="lang_selector" class="mobile_menu bg-primary {{mobile_menu?'mobile_menu_show':'mobile_menu_hide'}}">
    <div>
        <div class="relative text-center ml-auto mr-auto"
            *ngFor="let t of translate.getTranslates() | keyvalue">
            <a (click)="changeTranslate(t.key)" class="item">{{translate.translate(t.key)}}</a>
        </div>
    </div>
</div>