import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {

    if (localStorage.getItem("jwt")) {
      localStorage.removeItem("jwt");
    }
    setTimeout(() => {
      this.router.navigate(["/ds_admin/login"]);
    }, 1000);
  }

}
