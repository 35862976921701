<app-preloader *ngIf="preloader"></app-preloader>
<div class="bg-primary text-white {{!func.isDesktop()?'h-100':''}}">

    <div class="bubblediv">
        <ng-lottie width="100vw" class="bubble" [options]="optionsbubble"></ng-lottie>
        <ng-lottie *ngIf="anim2" width="100vw" class="bubble bubble-2" [options]="optionsbubble">
        </ng-lottie>
        <ng-lottie *ngIf="anim3" width="100vw" class="bubble bubble-3" [options]="optionsbubble">
        </ng-lottie>
    </div>

    <app-header [fixed]="true"></app-header>
    <app-header></app-header>
    <div class="spacing"></div>

    <div class="container no-overflow">
        <div class="row">
            <div class="col text-center">
                <h1 class="display-3 bold" data-aos="fade-down" data-aos-delay="500" data-aos-placement="center-center">
                    {{translate.translate('searching_partner')}}</h1>
                <div class="spacing-mini"></div>
                <h3 data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
                    {{translate.translate('business_description_extended')}}</h3>
            </div>
        </div>
        <div class="spacing"></div>
        <div class="row">
            <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-right" data-aos-delay="500"
                data-aos-placement="center-center">
                <div class="row">
                    <div class="col-2 text-center">
                        <ng-lottie height="80%" [options]="element1"></ng-lottie>
                    </div>
                    <div class="col mobile-center">
                        <h3 class="bold">{{translate.translate("development")}}</h3>
                        <p class="light">{{translate.translate("development_description")}}</p>
                    </div>
                </div>
            </div>
            <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-left" data-aos-delay="500"
                data-aos-placement="center-center">
                <div class="row">
                    <div class="col-2 text-center">
                        <ng-lottie height="80%" [options]="element2"></ng-lottie>
                    </div>
                    <div class="col mobile-center">
                        <h3 class="bold">{{translate.translate("viability")}}</h3>
                        <p class="light">{{translate.translate("viability_description")}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacing-mini"></div>
        <div class="row">
            <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-right" data-aos-delay="500"
                data-aos-placement="center-center">
                <div class="row">
                    <div class="col-2 text-center">
                        <ng-lottie height="80%" [options]="element3"></ng-lottie>
                    </div>
                    <div class="col mobile-center">
                        <h3 class="bold">{{translate.translate("financing")}}</h3>
                        <p class="light">{{translate.translate("financing_description")}}</p>
                    </div>
                </div>
            </div>
            <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-left" data-aos-delay="500"
                data-aos-placement="center-center">
                <div class="row">
                    <div class="col-2 text-center">
                        <ng-lottie height="80%" [options]="element4"></ng-lottie>
                    </div>
                    <div class="col mobile-center">
                        <h3 class="bold">{{translate.translate("paperwork")}}</h3>
                        <p class="light">{{translate.translate("paperwork_description")}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="spacing"></div>
        <div class="spacing"></div>
        <div class="spacing"></div>
    </div>
</div>
<div class="{{func.isMobile()?'container-fluid':'container'}} card rounded-lg shadow-lg p-4 mt--200" data-aos="fade-up"
    data-aos-placement="center-center" data-aos-delay="1000">
    <div class="row">
        <div class="col">
            <p>{{translate.translate("next_steps")}}</p>
            <ul>
                <li>{{translate.translate("step_1")}}</li>
                <li>{{translate.translate("step_2")}}</li>
                <li>{{translate.translate("step_3")}}</li>
                <li>{{translate.translate("step_4")}}</li>
            </ul>
            <hr>
            <label class="h5" for="name">{{translate.translate('email')}}</label>
            <input type="email" name="email" #email class="form-control"><br>

            <label class="h5" for="name">{{translate.translate('what_your_name')}}</label>
            <input name="name" #name class="form-control"><br>

            <label class="h5" for="name">{{translate.translate('partners_name')}} <i
                    ngbTooltip="{{translate.translate('if_exists')}}" class="fas fa-info-circle"></i></label>
            <input name="othernames" #othernames class="form-control"><br>

            <label class="h5" for="name">{{translate.translate('where_you_live')}} <i
                    ngbTooltip="{{translate.translate('you_and_partners')}}" class="fas fa-info-circle"></i></label>
            <input name="live" #live class="form-control"><br>

            <label class="h5" for="name">{{translate.translate('projects_name')}} <i
                    ngbTooltip="{{translate.translate('if_exist')}}" class="fas fa-info-circle"></i></label>
            <input name="pname" #pname class="form-control"><br>

            <label class="h5" for="name">{{translate.translate('project_description')}} <i
                    ngbTooltip="{{translate.translate('explain')}}" class="fas fa-info-circle"></i></label>
            <textarea name="about" #about class="form-control"></textarea><br>

            <re-captcha (resolved)="resolved($event)" siteKey="6Lf_yicbAAAAABRvCabfkqPzoB9gnCnd7xpqYsOq"></re-captcha>
            <br>

            <button (click)="sendRequest(name.value,email.value,othernames.value,live.value,pname.value,about.value)"
                class="btn btn-lg btn-primary w-100 text-white">{{translate.translate("send")}}</button>

            <p *ngIf="error">{{error}}</p>
        </div>
    </div>
</div>
<div class="spacing"></div>