import { enableProdMode } from '@angular/core';

enableProdMode();
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { JoinComponent } from './components/join/join.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageComponent } from './components/page/page.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { CookiesComponent } from './components/pages/cookies/cookies.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { JobComponent } from './components/pages/job/job.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AboutComponent } from './components/pages/about/about.component';
import { CallComponent } from './components/call/call.component';
import { HttpClientModule } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { ConsentComponent } from './components/consent/consent.component';
import { OrderComponent } from './components/pages/order/order.component';
import { UnsubscribeComponent } from './components/pages/unsubscribe/unsubscribe.component';
import { FormsModule } from '@angular/forms';
import { PostComponent } from './components/post/post.component';
import { AdminComponent } from './components/pages/admin/admin.component';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { PostListComponent } from './components/pages/admin/post-list/post-list.component';
import { CreatePostComponent } from './components/pages/admin/create-post/create-post.component';
import { EditPostComponent } from './components/pages/admin/edit-post/edit-post.component';
import { LogoutComponent } from './components/pages/admin/logout/logout.component';
import { LoginComponent } from './components/pages/admin/login/login.component';
import { DashboardComponent } from './components/pages/admin/dashboard/dashboard.component';
import { PostsComponent } from './components/pages/posts/posts.component';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HeaderComponent,
    FooterComponent,
    JoinComponent,
    PreloaderComponent,
    PageComponent,
    PrivacyComponent,
    CookiesComponent,
    TermsComponent,
    ContactComponent,
    JobComponent,
    NotfoundComponent,
    AboutComponent,
    CallComponent,
    ConsentComponent,
    OrderComponent,
    UnsubscribeComponent,
    PostComponent,
    AdminComponent,
    PostListComponent,
    CreatePostComponent,
    EditPostComponent,
    LogoutComponent,
    LoginComponent,
    DashboardComponent,
    PostsComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    NgbModule,
    HttpClientModule,
    RecaptchaModule,
    FormsModule,
    SocialLoginModule,
    HighlightModule
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      }
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '822159484150-i0dnlh7qd3dno8k51rluj1am4dj5h1ku.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
