import { Component, OnInit } from '@angular/core';

import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Code from '@editorjs/code';
import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import Underline from '@editorjs/underline';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import AttachesTool from '@editorjs/attaches';
import { EndpointService } from 'src/app/services/endpoint.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {

  public slug: string = "";
  public title: string = "";

  public body: any;

  public publishhover: boolean = false;
  public savehover: boolean = false;

  public editorjs: any;

  public saved: any = false;

  public saving: boolean = false;
  public publishing: boolean = false;

  public slugOK: boolean = false;

  public lang: string = "en";

  constructor(private api: EndpointService, public router: Router) {
    this.saved = router.url.replace("/ds_admin/post/edit", "");
  }

  async ngOnInit() {

    await this.api.get("/post" + this.saved, {}, true).toPromise().then(res => {
      if (res) {
        this.title = res.title;
        this.slug = res.slug;
        this.body = JSON.parse(res.body);
        this.lang = res.lang;
      }
    });

    this.editorjs = new EditorJS({
      holder: 'editorjs',
      placeholder: 'Description',
      tools: {
        header: {
          class: Header,
          inlineToolbar: ['link']
        },
        code: {
          class: Code,
          inlineToolbar: true
        },
        list: {
          class: NestedList,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: 'https://dastcms-api.dast.software/v1/media/image',
              byUrl: 'https://dastcms-api.dast.software/v1/media/image'
            },
            additionalRequestHeaders: { "Authorization": "Bearer " + localStorage.getItem("jwt") }
          }
        },
        underline: Underline,
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M',
        },
        Marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+M',
        },
        quote: Quote,
        table: Table,
        attaches: {
          class: AttachesTool,
          config: {
            endpoint: 'https://dastcms-api.dast.software/v1/media/attachment',
            additionalRequestHeaders: { "Authorization": "Bearer " + localStorage.getItem("jwt") }
          }
        },
      },
      data: {
        blocks: this.body
      }
    })
  }

  createSlug(title: any) {
    title = title.toLowerCase();
    title = title.replaceAll(" ", "-");
    title = title.replaceAll(/[`~!@#$%^&*()·€¬¿|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    title = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return title;
  }

  changingTitle(event) {
    this.title = event.target.value;
    this.slug = this.createSlug(this.title);
    this.changingSlug(this.slug);
  }

  changingSlug(event) {
    this.slug = this.createSlug(event);
    if (event.length > 2) {
      this.api.get("/post/slug/existance/" + event, {}, true).toPromise().then(res => {
        this.slugOK = !res;
      });
    }
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  showScroll() {
    return document.documentElement.scrollTop > 200;
  }

  async save() {

    if (!this.title || !this.slug) {
      alert("Please put a title or slug before save");
      return;
    }

    this.saving = true;

    await this.editorjs.save().then(async (outputData) => {
      let obj: any = {
        "title": this.title,
        "slug": this.slug,
        "lang": this.lang,
        "body": JSON.stringify(outputData.blocks),
      }

      let featured: boolean = false;

      for (let o = 0; o < outputData.blocks.length; o++) {
        if (!featured) {
          if (outputData.blocks[o].type == "image") {
            featured = true;
            obj["featured_image_url"] = outputData.blocks[o].data.file.url;
          }
        }
      }

      await this.api.put("/post" + this.saved, obj, {}, true).toPromise().then(res => {
        this.saving = false;
      })
    }).catch((error) => {
      this.saving = false;
      console.log('Saving failed: ', error)
    });
  }

  async publish() {
    this.publishing = true;
    await this.save();
    await this.api.put("/post/status" + this.saved, {
      "status": "PUBLISH"
    }, {}, true).toPromise().then(res => {
      this.publishing = false;
      window.open("/post/" + this.slug);
    });
  }

}
