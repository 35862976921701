<!-- English -->
<div *ngIf="translate.getLang()=='en'">
    <p>This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can
        understand what type of cookies We use, or the information We collect using Cookies and how that information is
        used.</p>
    <p>Cookies do not typically contain any information that personally identifies a user, but personal information that
        we store about You may be linked to the information stored in and obtained from Cookies. For further information
        on how We use, store and keep your personal data secure, see our Privacy Policy.</p>
    <p>We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies
        We use.</p>
    <h1>Interpretation and Definitions</h1>
    <h2>Interpretation</h2>
    <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
    </p>
    <h2>Definitions</h2>
    <p>For the purposes of this Cookies Policy:</p>
    <ul>
        <li><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or
            &quot;Our&quot; in this Cookies Policy) refers to Dast INC, Street.</li>
        <li><strong>Cookies</strong> means small files that are placed on Your computer, mobile device or any other
            device by a website, containing details of your browsing history on that website among its many uses.</li>
        <li><strong>Website</strong> refers to Dast, accessible from <a href="https://www.dast.software"
                rel="external nofollow noopener" target="_blank">https://www.dast.software</a></li>
        <li><strong>You</strong> means the individual accessing or using the Website, or a company, or any legal entity
            on behalf of which such individual is accessing or using the Website, as applicable.</li>
    </ul>
    <h1>The use of the Cookies</h1>
    <h2>Type of Cookies We Use</h2>
    <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal
        computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
        browser.</p>
    <p>We use both session and persistent Cookies for the purposes set out below:</p>
    <ul>
        <li>
            <p><strong>Necessary / Essential Cookies</strong></p>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
                enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
                user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.</p>
        </li>
        <li>
            <p><strong>Functionality Cookies</strong></p>
            <p>Type: Persistent Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                remembering your login details or language preference. The purpose of these Cookies is to provide You
                with a more personal experience and to avoid You having to re-enter your preferences every time You use
                the Website.</p>
        </li>
    </ul>
    <h2>Your Choices Regarding Cookies</h2>
    <p>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your
        browser and then delete the Cookies saved in your browser associated with this website. You may use this option
        for preventing the use of Cookies at any time.</p>
    <p>If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some
        features may not function properly.</p>
    <p>If You'd like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help
        pages of your web browser.</p>
    <ul>
        <li>
            <p>For the Chrome web browser, please visit this page from Google: <a
                    href="https://support.google.com/accounts/answer/32050" rel="external nofollow noopener"
                    target="_blank">https://support.google.com/accounts/answer/32050</a></p>
        </li>
        <li>
            <p>For the Internet Explorer web browser, please visit this page from Microsoft: <a
                    href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener"
                    target="_blank">http://support.microsoft.com/kb/278835</a></p>
        </li>
        <li>
            <p>For the Firefox web browser, please visit this page from Mozilla: <a
                    href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                    rel="external nofollow noopener"
                    target="_blank">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a>
            </p>
        </li>
        <li>
            <p>For the Safari web browser, please visit this page from Apple: <a
                    href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                    rel="external nofollow noopener"
                    target="_blank">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a>
            </p>
        </li>
    </ul>
    <p>For any other web browser, please visit your web browser's official web pages.</p>
    <h2>More Information about Cookies</h2>
    <p>You can learn more about cookies: <a href="https://en.wikipedia.org/wiki/HTTP_cookie" target="_blank">What
            Are Cookies?</a>.</p>
    <h2>Contact Us</h2>
    <p>If you have any questions about this Cookies Policy, You can contact us:</p>
    <ul>
        <li>By email: info@dastsoftware.com</li>
    </ul>
</div>
<!-- Spanish -->
<div *ngIf="translate.getLang()=='es'">
    <div _ngcontent-eni-c109="">
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Esta Política de Cookies explica qué son las Cookies y cómo las
                    usamos. </font>
                <font style="vertical-align: inherit;">Debe leer esta política para que pueda comprender qué tipo de
                    cookies usamos, o la información que recopilamos usando cookies y cómo se usa esa información.
                </font>
            </font>
        </p>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Las cookies no suelen contener ninguna información que
                    identifique personalmente a un usuario, pero la información personal que almacenamos sobre usted
                    puede estar vinculada a la información almacenada y obtenida de las cookies. </font>
                <font style="vertical-align: inherit;">Para obtener más información sobre cómo usamos, almacenamos y
                    mantenemos seguros sus datos personales, consulte nuestra Política de privacidad.</font>
            </font>
        </p>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">No almacenamos información personal confidencial, como
                    direcciones de correo, contraseñas de cuentas, etc. en las cookies que utilizamos.</font>
            </font>
        </p>
        <h1 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Interpretación y definiciones</font>
            </font>
        </h1>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Interpretación</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Las palabras cuya letra inicial está en mayúscula tienen
                    significados definidos en las siguientes condiciones. </font>
                <font style="vertical-align: inherit;">Las siguientes definiciones tendrán el mismo significado
                    independientemente de que aparezcan en singular o en plural.</font>
            </font>
        </p>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Definiciones</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">A los efectos de esta Política de Cookies:</font>
            </font>
        </p>
        <ul _ngcontent-eni-c109="">
            <li _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Compañía</font>
                    </font>
                </strong>
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;"> (referida como "la Compañía", "Nosotros", "Nos" o "Nuestro"
                        en esta Política de Cookies) se refiere a Dast INC, Street.</font>
                </font>
            </li>
            <li _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Las cookies son</font>
                    </font>
                </strong>
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;"> pequeños archivos que un sitio web coloca en su computadora,
                        dispositivo móvil o cualquier otro dispositivo, que contienen detalles de su historial de
                        navegación en ese sitio web, entre sus muchos usos.</font>
                </font>
            </li>
            <li _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">El sitio web se</font>
                    </font>
                </strong>
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;"> refiere a Dast, accesible desde </font>
                </font><a _ngcontent-eni-c109="" href="https://www.dast.software" rel="external nofollow noopener"
                    target="_blank">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">https://www.dast.software</font>
                    </font>
                </a>
            </li>
            <li _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Usted se</font>
                    </font>
                </strong>
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;"> refiere a la persona que accede o utiliza el sitio web, o
                        una empresa o cualquier entidad legal en nombre de la cual dicha persona accede o utiliza el
                        sitio web, según corresponda.</font>
                </font>
            </li>
        </ul>
        <h1 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">El uso de las Cookies</font>
            </font>
        </h1>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Tipo de cookies que usamos</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Las cookies pueden ser cookies "persistentes" o de "sesión".
                </font>
                <font style="vertical-align: inherit;">Las cookies persistentes permanecen en su computadora personal o
                    dispositivo móvil cuando se desconecta, mientras que las cookies de sesión se eliminan tan pronto
                    como cierra su navegador web.</font>
            </font>
        </p>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Utilizamos cookies de sesión y persistentes para los fines que se
                    establecen a continuación:</font>
            </font>
        </p>
        <ul _ngcontent-eni-c109="">
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Cookies necesarias / esenciales</font>
                        </font>
                    </strong></p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Tipo: Cookies de sesión</font>
                    </font>
                </p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Administrado por: Nosotros</font>
                    </font>
                </p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Propósito: Estas cookies son esenciales para brindarle
                            los servicios disponibles a través del sitio web y para permitirle utilizar algunas de sus
                            funciones. </font>
                        <font style="vertical-align: inherit;">Ayudan a autenticar a los usuarios y a prevenir el uso
                            fraudulento de las cuentas de usuario. </font>
                        <font style="vertical-align: inherit;">Sin estas cookies, los servicios que ha solicitado no se
                            pueden proporcionar, y solo usamos estas cookies para brindarle esos servicios.</font>
                    </font>
                </p>
            </li>
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109=""><strong _ngcontent-eni-c109="">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">Cookies de funcionalidad</font>
                        </font>
                    </strong></p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Tipo: Cookies persistentes</font>
                    </font>
                </p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Administrado por: Nosotros</font>
                    </font>
                </p>
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Propósito: estas cookies nos permiten recordar las
                            elecciones que realiza cuando utiliza el sitio web, como recordar sus datos de inicio de
                            sesión o su preferencia de idioma. </font>
                        <font style="vertical-align: inherit;">El propósito de estas cookies es brindarle una
                            experiencia más personal y evitar que tenga que volver a ingresar sus preferencias cada vez
                            que utiliza el sitio web.</font>
                    </font>
                </p>
            </li>
        </ul>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Sus opciones con respecto a las cookies</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;" class="">Si prefiere evitar el uso de cookies en el sitio web,
                    primero debe deshabilitar el uso de cookies en su navegador y luego eliminar las cookies guardadas
                    en su navegador asociado con este sitio web. </font>
                <font style="vertical-align: inherit;">Puede utilizar esta opción para evitar el uso de cookies en
                    cualquier momento.</font>
            </font>
        </p>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si no acepta nuestras cookies, puede experimentar algunos
                    inconvenientes en el uso del sitio web y es posible que algunas funciones no funcionen
                    correctamente.</font>
            </font>
        </p>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si desea eliminar las cookies o indicarle a su navegador web que
                    elimine o rechace las cookies, visite las páginas de ayuda de su navegador web.</font>
            </font>
        </p>
        <ul _ngcontent-eni-c109="">
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Para el navegador web Chrome, visite esta página de
                            Google: </font>
                    </font><a _ngcontent-eni-c109="" href="https://support.google.com/accounts/answer/32050"
                        rel="external nofollow noopener" target="_blank">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">https://support.google.com/accounts/answer/32050
                            </font>
                        </font>
                    </a>
                </p>
            </li>
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Para el navegador web Internet Explorer, visite esta
                            página de Microsoft: </font>
                    </font><a _ngcontent-eni-c109="" href="http://support.microsoft.com/kb/278835"
                        rel="external nofollow noopener" target="_blank">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">http://support.microsoft.com/kb/278835</font>
                        </font>
                    </a>
                </p>
            </li>
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Para el navegador web Firefox, visite esta página de
                            Mozilla: </font>
                    </font><a _ngcontent-eni-c109=""
                        href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                        rel="external nofollow noopener" target="_blank">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">
                                https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</font>
                        </font>
                    </a>
                </p>
            </li>
            <li _ngcontent-eni-c109="">
                <p _ngcontent-eni-c109="">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">Para el navegador web Safari, visite esta página de
                            Apple: </font>
                    </font><a _ngcontent-eni-c109=""
                        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                        rel="external nofollow noopener" target="_blank">
                        <font style="vertical-align: inherit;">
                            <font style="vertical-align: inherit;">
                                https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                            </font>
                        </font>
                    </a>
                </p>
            </li>
        </ul>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Para cualquier otro navegador web, visite las páginas web
                    oficiales de su navegador web.</font>
            </font>
        </p>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Más información sobre cookies</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Puede obtener más información sobre las cookies: </font>
            </font><a _ngcontent-eni-c109="" href="https://es.wikipedia.org/wiki/Cookie_(informática)" target="_blank">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">¿Qué son las cookies? </font>
                </font>
            </a>
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">.</font>
            </font>
        </p>
        <h2 _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Contáctenos</font>
            </font>
        </h2>
        <p _ngcontent-eni-c109="">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Si tiene alguna duda sobre esta Política de Cookies, puede
                    contactarnos:</font>
            </font>
        </p>
        <ul _ngcontent-eni-c109="">
            <li _ngcontent-eni-c109="">
                <font style="vertical-align: inherit;">
                    <font style="vertical-align: inherit;">Por correo electrónico: info@dastsoftware.com</font>
                </font>
            </li>
        </ul>
    </div>
</div>