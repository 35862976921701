import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private api: EndpointService,public translate:TranslateService) { }

  public sent: boolean = false;

  public email:string = "";

  erroroptions: AnimationOptions = {
    path: 'https://assets5.lottiefiles.com/private_files/lf30_z1sghrbu.json',
    loop: false
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.email && !this.sent) {
        this.email = params.email;
        this.api.post("/cform.php", { "unsubscribe": "1", "email": params.email }).toPromise().then(res => {
        }).catch(e => {
          console.log("ERROR ", e);
        });
        this.sent = true;
      }
    });

  }

}
