<div class="container p-2">
    <div class="row">
        <div class="col">
            <img style="transform: scale(0.6);" class="text-center ml-auto mr-auto" src="../../../assets/img/resize.png"><hr>
            <h1>{{translate.translate("consent_before")}}</h1>
            <p>{{translate.translate("consent_sub")}}</p>
            <ol>
                <li>{{translate.translate("consent_list_one")}}</li>
                <li>{{translate.translate("consent_list_two")}}</li>
            </ol>
            <p>{{translate.translate("consent_third_cookies")}}</p>
            <ol>
                <li><a class="text-primary" href="https://policies.google.com/technologies/cookies" target="_blank">Google</a></li>
                <li><a class="text-primary" href="https://www.tawk.to/privacy-policy/" target="_blank">Tawk chat</a></li>
            </ol>
            <p>{{translate.translate("consent_contact")}} (info@dast.software)</p>
            <ngb-accordion>
                <ngb-panel id="preventchange-1" title="Política de cookies">
                  <ng-template ngbPanelContent>
                    <app-cookies></app-cookies>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion><br>
            <button (click)="cookieConsent()" class="btn btn-primary text-right ml-auto">{{translate.translate("accept")}}</button>
        </div>
    </div>
</div>