<div *ngIf="translate.getLang()=='en'">
    Dast is a software development company born in the United States (Delaware). We work with companies all around the world, offering solutions to problems they may have today and tomorrow. 

We don't neglect design and marketing. We are open to any proposal from any company or individual, regardless of their origin, studies or experience. 

We try to achieve success with big plans, big ideas, innovating and digitalizing sectors.
</div>

<div *ngIf="translate.getLang()=='es'">
    Dast es una compañía desarrolladora de software nacida en Estados unidos (Delaware), trabajamos con empresas alrededor del mundo, ofreciendo soluciones a los problemas que puedan tener hoy y mañana.

    No descuidamos el diseño y el marketing, estamos abiertos a cualquier propuesta de cualquier empresa o individuo, sin importar su procedencia, estudios o experiencia.

    Tratamos de alcanzar el éxito con grandes planes, con grandes ideas, innovando y digitalizando sectores.
</div>