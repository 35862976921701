import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  constructor(private router: Router, public translate: TranslateService) { }

  ngOnInit(): void {

    if (localStorage.getItem("cookies")) {
      this.router.navigate(["/"]);
    }

  }

  cookieConsent() {
    localStorage.setItem("cookies", "1");
    location.href = "/";
    if (localStorage.getItem("redir")) {
      location.href = localStorage.getItem("redir");
    }
  }

}
