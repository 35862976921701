import { Component, OnInit,NgZone } from '@angular/core';
import moment from 'moment';
import { AnimationOptions } from 'ngx-lottie';
import { EndpointService } from 'src/app/services/endpoint.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  public anim2:boolean = false;
  public anim3:boolean = false;
  public preloader:boolean = true;
  public posts:any;
  public lang:string;

  options: AnimationOptions = {
    path: 'assets/json/lf20_mjbrijxn.json'
  };

  optionsbubble: AnimationOptions = {
    path: 'assets/json/lf30_editor_pj1ryc5q.json'
  };

  optionssection_1: AnimationOptions = {
    path: 'assets/json/lf30_GjhcdO.json'
  };

  optionssection_2: AnimationOptions = {
    path: 'assets/json/lf20_rycdh53q.json'
  };

  optionssection_3: AnimationOptions = {
    path: 'assets/json/lf20_h59xofz0.json'
  };

  optionssection_4: AnimationOptions = {
    path: 'assets/json/lf20_dl87KC.json'
  };

  optionssection_5: AnimationOptions = {
    path: 'assets/json/lf20_obkemuop.json'
  };

  optionssection_6: AnimationOptions = {
    path: 'assets/json/lf20_1KjmRZ.json'
  };

  optionssection_7: AnimationOptions = {
    path: 'assets/json/lf20_pwohahvd.json'
  };

  element1: AnimationOptions = {
    path: 'assets/json/lf20_CZ1Za7.json'
  };

  element2: AnimationOptions = {
    path: 'assets/json/lf20_7fe5oU.json'
  };

  element3: AnimationOptions = {
    path: 'assets/json/lf20_JbiDg7.json'
  };

  element4: AnimationOptions = {
    path: 'assets/json/lf20_94KptK.json'
  };

  calltoaction: AnimationOptions = {
    path: 'assets/json/lf30_editor_3yia4ig0.json'
  };

  constructor(public func:FunctionsService,public translate:TranslateService,private api:EndpointService) { }

  ngOnInit(): void {

    this.lang = this.translate.getLang();

    this.api.get("/post?page=1&lang="+this.lang,{},true).toPromise().then(res=>{
      if (res) {
        this.posts = res.data;
      }
    })

    setTimeout(() => {
      this.anim2 = true;
    }, 100);

    setTimeout(() => {
      this.anim3 = true;
    }, 200);

  }

  getObject(num:number) {
    return Array(num).map((x,i)=>i);
  }

  load() {
    this.preloader = false;
  }

  getCoolDate(date) {
    moment.locale(this.translate.getLang());
    return moment(date).fromNow();
  }

  private checker:boolean = false;

  async checkLang() {
    if (this.checker) {return;}
    if (localStorage.getItem("lang") !== this.lang) {
      this.checker = true;
      this.lang = localStorage.getItem("lang");
      await this.api.get("/post?page=1&lang="+this.lang,{},true).toPromise().then(res=>{
        if (res) {
          this.posts = res.data;
          this.checker = false;
        }
      });
    }
  }

}
