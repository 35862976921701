import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public date:number = new Date().getFullYear();

  public screen:string = "";

  constructor(public func:FunctionsService,public translate:TranslateService,private router:Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.screen = val.url;
      }
    });
  }

  ngOnInit(): void {
    
  }

  isAdmin() {
    return this.screen.includes("ds_admin");
  }

}
