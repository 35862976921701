<app-preloader *ngIf="preloader"></app-preloader>
<div class="bg-primary text-white hero {{!func.isDesktop()?'h-100':''}}">

    <div class="bubblediv">
        <ng-lottie width="100vw" class="bubble" [options]="optionsbubble"></ng-lottie>
        <ng-lottie *ngIf="anim2" width="100vw" class="bubble bubble-2" [options]="optionsbubble">
        </ng-lottie>
        <ng-lottie *ngIf="anim3" width="100vw" class="bubble bubble-3" [options]="optionsbubble">
        </ng-lottie>
    </div>

    <app-header [fixed]="true"></app-header>
    <app-header></app-header>

    <div class="container no-overflow">
        <div class="row full-center">
            <div class="col-7 mt-auto mb-auto" data-aos="fade-right" data-aos-delay="500"
                data-aos-placement="center-center">
                <ng-lottie width="{{func.isDesktop()?'100%':'50%'}}" (enterFrame)="preloader=false" [options]="options">
                </ng-lottie>
            </div>
            <div class="col relative mt-auto mb-auto" data-aos="fade-left" data-aos-delay="500"
                data-aos-placement="center-center">
                <h1 class="display-3">{{translate.translate("talk_with_us")}}</h1>
                <p>{{translate.translate("talk_description")}}</p>
                <a routerLink="/order"><button class="btn btn-light">{{translate.translate("we_call_u")}}</button></a>
            </div>
        </div>
    </div>

</div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
    <path fill="#331654" fill-opacity="1" d="M0,288L1440,96L1440,0L0,0Z"></path>
</svg>

<div class="spacing"></div>

<div class="container no-overflow">
    <!-- Section 1 -->
    <div class="row" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col">
            <div class="section section-mini bg-section"></div>
            <div class="section section-mini bg-section section-nofirst"></div>
            <div class="section bg-section section-nofirst text-center d-flex justify-content-center">
                <div class="mt-auto mb-auto ml-auto mr-auto w-100 text-center">
                    <div class="tag tag-section text-center">{{translate.translate("posts")}}</div><br>
                    <h1 class="display-3 bold color-first">{{translate.translate("our_contributions")}}</h1>
                    <h3 class="light color-first text-center">{{translate.translate("contributions_description")}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <div class="row">
        <div class="col-4" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center" *ngFor="let p of posts">
            <a routerLink="/post/{{p.slug}}">
                <div class="card shadow rounded mb-5">
                    <img *ngIf="p.featured_image_url" class="card-img-top" [src]="p.featured_image_url">
                    <div class="card-body">
                        <h5 class="card-title text-center">{{p.title}}</h5>
                        <p class="text-center"><i class="fas fa-clock"></i> {{getCoolDate(p.updated_at)}}</p>
                        <p class="text-center color-first"><i class="fas fa-user-tie"></i> {{p.author}}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div *ngIf="checkLang()"></div>
    <div class="spacing"></div>
    <div class="row" ata-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col d-flex">
            <a class="ml-auto mr-auto" routerLink="/posts"><button class="btn btn-light">{{translate.translate('more_posts')}}</button></a>
        </div>
    </div>
    <div class="spacing"></div>
    <!-- Section 2 -->
    <div id="services" class="row" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col">
            <div class="section section-mini bg-section-two"></div>
            <div class="section section-mini bg-section-two section-nofirst"></div>
            <div class="section bg-section-two section-nofirst text-center d-flex justify-content-center">
                <div class="mt-auto mb-auto ml-auto mr-auto w-100 text-center">
                    <div class="tag tag-section-two text-center">{{translate.translate("services")}}</div><br>
                    <h1 class="display-3 bold tag-section-two">{{translate.translate("our_specialization")}}</h1>
                    <h3 class="light tag-section-two text-center">{{translate.translate("services_description")}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row" data-aos="fade-left" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_1"></ng-lottie>
        </div>
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("empathy")}}</h3>
            <p class="light">{{translate.translate("empathy_description")}}</p>
        </div>
    </div>
    <div class="row" data-aos="fade-right" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("responsability")}}</h3>
            <p class="light">{{translate.translate("responsability_description")}}</p>
        </div>
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_2"></ng-lottie>
        </div>
    </div>
    <div class="row" data-aos="fade-left" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_3"></ng-lottie>
        </div>
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("professionalism")}}</h3>
            <p class="light">{{translate.translate("professionalism_description")}}</p>
        </div>
    </div>
    <div class="row" data-aos="fade-right" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("execution")}}</h3>
            <p class="light">{{translate.translate("execution_description")}}</p>
        </div>
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_4"></ng-lottie>
        </div>
    </div>
    <div class="row" data-aos="fade-left" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_5"></ng-lottie>
        </div>
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("aesthetics")}}</h3>
            <p class="light">{{translate.translate("aesthetics_description")}}</p>
        </div>
    </div>
    <div class="row" data-aos="fade-right" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("adaptability")}}</h3>
            <p class="light">{{translate.translate("adaptability_description")}}</p>
        </div>
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_6"></ng-lottie>
        </div>
    </div>
    <div class="row" data-aos="fade-left" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center mt-auto mb-auto ml-auto mr-auto">
            <ng-lottie class="text-center" height="400px" [options]="optionssection_7"></ng-lottie>
        </div>
        <div class="col text-center mt-auto mb-auto">
            <h3 class="bold">{{translate.translate("prosperity")}}</h3>
            <p class="light">{{translate.translate("prosperity_description")}}</p>
        </div>
    </div>
    <div class="spacing"></div>
    <!--<div class="row {{func.isMobile()?'':'w-50'}} ml-auto mr-auto" data-aos="fade-up" data-aos-delay="500"
        data-aos-placement="center-center">
        <div class="col text-center {{func.isMobile()?'':'w-25'}}">
            <a routerLink="/order"><button
                    class="btn btn-outline-primary btn-lg">{{translate.translate('we_call_u')}}</button></a>
        </div>
    </div>
    <div class="spacing"></div>-->
    <!-- Section 3 -->
    <!--<div class="row" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col">
            <div class="section section-mini bg-section-three"></div>
            <div class="section section-mini bg-section-three section-nofirst"></div>
            <div class="section bg-section-three section-nofirst text-center d-flex justify-content-center">
                <div class="mt-auto mb-auto ml-auto mr-auto w-100 text-center">
                    <div class="tag tag-section-three text-center">{{translate.translate('business')}}</div><br>
                    <h1 class="display-3 bold tag-section-three">{{translate.translate('searching_partner')}}</h1>
                    <h3 class="light tag-section-three text-center">{{translate.translate('business_description')}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="spacing-mini"></div>
    <div class="row">
        <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-right" data-aos-delay="500"
            data-aos-placement="center-center">
            <div class="row">
                <div class="col-2 text-center">
                    <ng-lottie height="80%" [options]="element1"></ng-lottie>
                </div>
                <div class="col mobile-center">
                    <h3 class="bold">{{translate.translate("development")}}</h3>
                    <p class="light">{{translate.translate("development_description")}}</p>
                </div>
            </div>
        </div>
        <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-left" data-aos-delay="500"
            data-aos-placement="center-center">
            <div class="row">
                <div class="col-2 text-center">
                    <ng-lottie height="80%" [options]="element2"></ng-lottie>
                </div>
                <div class="col mobile-center">
                    <h3 class="bold">{{translate.translate("viability")}}</h3>
                    <p class="light">{{translate.translate("viability_description")}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="spacing-mini"></div>
    <div class="row">
        <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-right" data-aos-delay="500"
            data-aos-placement="center-center">
            <div class="row">
                <div class="col-2 text-center">
                    <ng-lottie height="80%" [options]="element3"></ng-lottie>
                </div>
                <div class="col mobile-center">
                    <h3 class="bold">{{translate.translate("financing")}}</h3>
                    <p class="light">{{translate.translate("financing_description")}}</p>
                </div>
            </div>
        </div>
        <div class="{{func.isMobile()?'col-2':'col'}} mt-auto mb-auto" data-aos="fade-left" data-aos-delay="500"
            data-aos-placement="center-center">
            <div class="row">
                <div class="col-2 text-center">
                    <ng-lottie height="80%" [options]="element4"></ng-lottie>
                </div>
                <div class="col mobile-center">
                    <h3 class="bold">{{translate.translate("paperwork")}}</h3>
                    <p class="light">{{translate.translate("paperwork_description")}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <div class="row" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
        <div class="col text-center">
            <ng-lottie class="bubble_" [options]="calltoaction"></ng-lottie>
            <div class="bubble_block">
                <h1 class="display-3 bold tag-section-two">{{translate.translate("are_you_interested")}}</h1><br>
                <h3 class="light tag-section-two">{{translate.translate("are_you_interested_description")}}</h3><br>
                <a routerLink="/join"><button
                        class="btn btn-light btn-lg tag-section-two relative">{{translate.translate("contact_us")}}</button></a>
            </div>
        </div>
    </div>
    <div class="spacing"></div>
    <div class="spacing"></div>-->
</div>