import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsentComponent } from './components/consent/consent.component';
import { JoinComponent } from './components/join/join.component';
import { LandingComponent } from './components/landing/landing.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PageComponent } from './components/page/page.component';
import { AdminComponent } from './components/pages/admin/admin.component';
import { PostComponent } from './components/post/post.component';

const routes: Routes = [

  { path: '', component: LandingComponent },
  { path: 'join', component: JoinComponent },
  { path: 'legal/terms', component: PageComponent },
  { path: 'legal/privacy-policy', component: PageComponent },
  { path: 'legal/cookies-policy', component: PageComponent },
  { path: 'contact', component: PageComponent },
  { path: 'about', component: PageComponent },
  { path: 'job', component: PageComponent },
  { path: 'consent', component: ConsentComponent },
  { path: 'order', component: PageComponent },
  { path: 'unsubscribe', component: PageComponent },
  { path: 'post/:id', component: PostComponent },
  { path: 'posts', component: PageComponent },
  { path: 'ds_admin', component: AdminComponent },
  { path: 'ds_admin/login', component: AdminComponent },
  { path: 'ds_admin/logout', component: AdminComponent },
  { path: 'ds_admin/dashboard', component: AdminComponent },
  { path: 'ds_admin/posts', component: AdminComponent },
  { path: 'ds_admin/post/edit/:id', component: AdminComponent },
  { path: 'ds_admin/post/create', component: AdminComponent },
  { path: 'ds_admin/post/delete/:id', component: AdminComponent },
  { path: '**', component: NotfoundComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
