import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  public rslv: boolean = false;
  public error: any = false;
  public _name:string = "";
  public _email:string = "";

  constructor(public translate: TranslateService, private api: EndpointService, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.name) {
        this._name = params.name;
      }
      if (params.email) {
        this._email = params.email;
      }
    });
  }

  submitForm(business_name: string, name: string, email: string, message: string) {
    if (this.rslv) {
      if (business_name.length > 0, name.length > 0 && email.length > 0 && message.length > 0) {
        this.api.post("/cform.php", { "order_form": "1", "business_name": business_name, "name": name, "email": email, "message": message }).toPromise().then(res => {
        }).catch(e => {
          console.log("ERROR ", e);
        });
        this.error = this.translate.translate("thanks");
      } else {
        this.error = this.translate.translate("required_fields");
      }
    } else {
      this.error = "Google captcha error";
    }
  }

}
