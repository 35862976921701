import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(private http: HttpClient) { }

  public url: string = "https://dast.software/api";
  public posturl: string = "https://dastcms-api.dast.software/v1";
  public token: string = "Bearer " + localStorage.getItem("jwt");

  get(_url: string, _headers: any = false, isPostApi: boolean = false) {

    let url: string = this.url + _url;

    let headers = new HttpHeaders();

    if (isPostApi) {
      headers = new HttpHeaders()
        .set('Authorization', this.token);
      url = this.posturl + _url;
    }

    if (headers) {

      for (const [key, value] of Object.entries(_headers)) {
        if (value) {
          headers = headers.append(key.toString(), value.toString());
        } else {
          headers = headers.append(key.toString(), null);
        }
      }

      return this.http.get<any>(url, { headers: headers });
    } else {
      return this.http.get<any>(url);
    }
  }

  post(_url: string, body: any, _headers: any = false, isPostApi: boolean = false) {

    let url: string = this.url + _url;

    let headers = new HttpHeaders();

    if (isPostApi) {
      headers = new HttpHeaders()
        .set('Authorization', this.token);
      url = this.posturl + _url;
    }


    if (_headers) {
      for (const [key, value] of Object.entries(_headers)) {
        if (value) {
          headers = headers.append(key.toString(), value.toString());
        } else {
          headers = headers.append(key.toString(), null);
        }
      }
    }

    return this.http.post(url, body, { headers: headers });
  }

  put(_url: string, body: any, _headers: any = false, isPostApi: boolean = false) {

    let url: string = this.url + _url;

    let headers = new HttpHeaders();

    if (isPostApi) {
      headers = new HttpHeaders()
        .set('Authorization', this.token);
      url = this.posturl + _url;
    }


    if (_headers) {
      for (const [key, value] of Object.entries(_headers)) {
        if (value) {
          headers = headers.append(key.toString(), value.toString());
        } else {
          headers = headers.append(key.toString(), null);
        }
      }
    }

    return this.http.put(url, body, { headers: headers });
  }

  delete(_url: string, body: any, _headers: any = false) {

    let url: string = this.posturl + _url;

    let headers = new HttpHeaders();

    headers = new HttpHeaders()
      .set('Authorization', this.token);

    if (_headers) {
      for (const [key, value] of Object.entries(_headers)) {
        if (value) {
          headers = headers.append(key.toString(), value.toString());
        } else {
          headers = headers.append(key.toString(), null);
        }
      }
    }

    const options = {
      headers: headers,
      body: body,
    };

    return this.http.delete(url, options);
  }



}
