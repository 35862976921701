<form class="text-center">

    <select style="max-width:650px;" class="form-control ml-auto mr-auto" name="lang" [(ngModel)]="lang" (change)="changeLang($event.target.value)">
        <option value="en">English</option>
        <option value="es">Spanish</option>
    </select><br>

    <input placeholder="Title" style="max-width:650px;" name="title" [ngModel]="title"
        (change)="changingTitle($event)" class="form-control ml-auto mr-auto"><br>

    <div class="input-group mb-3 ml-auto mr-auto" style="max-width:650px;">
        <input placeholder="Slug" name="slug" [ngModel]="slug"
        (change)="changingSlug($event.target.value)" class="form-control">
        <div class="input-group-append">
            <span *ngIf="slugOK" class="input-group-text" id="basic-addon2"><i class="fas fa-check-circle text-success h3"></i></span>
            <span *ngIf="!slugOK" class="input-group-text" id="basic-addon2"><i class="fas fa-times-circle text-danger h3"></i></span>
        </div>
    </div><br>

    <div style="max-width:650px;" class="w-100 ml-auto mr-auto" id="editorjs" #editorjs></div>

    <button data-aos="fade-up" *ngIf="showScroll()" (click)="scrollTop()"
        style="position: fixed;right:1.5%;bottom:10%;opacity:0.8" class="btn btn-light"><i
            class="fas fa-chevron-up"></i></button>

    <button *ngIf="!saving&&(title&&slug)" data-aos="fade-up" data-placement="top" (click)="save()" (mouseover)="savehover=true"
        (mouseout)="savehover=false" style="position: fixed;left:20%;bottom:20%;opacity:0.5" class="btn btn-dark"><i
            class="fas fa-save"></i>{{savehover?' Save':''}}</button>
    <button *ngIf="saving&&(title&&slug)" (mouseover)="savehover=true" (mouseout)="savehover=false"
        style="position: fixed;left:20%;bottom:20%;opacity:0.5" class="btn btn-dark" disabled>
        <div class="spinner-border spinner-border-sm text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
    <button *ngIf="publishing&&(title&&slug&&saved)" style="position: fixed;left:20%;bottom:15%;opacity:0.5" class="btn btn-light"><i
            class="fas fa-check"></i>
        <div class="spinner-border spinner-border-sm text-light" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </button>
    <button *ngIf="!publishing&&(title&&slug&&saved)" data-aos="fade-up" data-placement="top" (click)="publish()"
        (mouseover)="publishhover=true" (mouseout)="publishhover=false"
        style="position: fixed;left:20%;bottom:15%;opacity:0.5" class="btn btn-light"><i
            class="fas fa-check"></i>{{publishhover?' Publish':''}}</button>
</form>