import { Component, HostListener, OnInit } from '@angular/core';
import moment from 'moment';
import { EndpointService } from 'src/app/services/endpoint.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {

  public posts: any;

  public page: number = 1;

  public hasNext: boolean = false;

  constructor(private api: EndpointService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.api.get("/post/all", {}, true).toPromise().then(res => {
      if (res) {
        this.posts = res.data;
        this.hasNext = res.hasNext;
      }
    })
  }

  getDate(date: string) {
    moment.locale(this.translate.getLang());
    return moment(date).fromNow();
  }

  deleteElement(id: number) {
    if (confirm("Are you sure?")) {
      this.api.delete("/post/" + id, {}).toPromise().then(res => {
        if (res) {
          for (let i = 0; i < this.posts.length; i++) {
            if (this.posts[i].id == id) {
              this.posts.splice(i, 1);
            }
          }
        }
      })
    }
  }

  private scrollLoaded: boolean = false;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    if (!this.hasNext) { return; }
    var a = document.documentElement.scrollTop;
      var b = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var c = a / b;

      if (c > 0.95) {

        if (this.scrollLoaded) { return; }

        this.page++;

        this.api.get("/post/all?page=" + this.page, {}, true).toPromise().then(res => {
          if (res) {
            for (let i = 0; i < res.data.length; i++) {
              this.posts.push(res.data[i]);
            }
            this.hasNext = res.hasNext;
          }
        })

      }
  }

}
