<label for="business_name">{{translate.translate("business_name")}}</label>
<input #business_name name="business_name" [(ngModel)]="_name" class="form-control"><br>

<label for="name">{{translate.translate("name")}}</label>
<input #name name="name" class="form-control"><br>

<label for="email">{{translate.translate("email")}}</label>
<input #email type="email" name="email" class="form-control" [(ngModel)]="_email"><br>

<label for="message">{{translate.translate("bussiness_description")}}</label>
<textarea #message name="message" class="form-control"></textarea><br>

<re-captcha (resolved)="rslv=true" siteKey="6Lf_yicbAAAAABRvCabfkqPzoB9gnCnd7xpqYsOq"></re-captcha><br>

<button type="button" (click)="submitForm(business_name.value,name.value,email.value,message.value)"
    class="btn btn-lg btn-primary w-100 text-white">{{translate.translate("send")}}</button>

<p *ngIf="error">{{error}}</p>