<div *ngIf="!isAdmin()" class="container" data-aos="fade-up" data-aos-delay="500" data-aos-placement="center-center">
    <div class="row">
        <div class="col-lg mobile-center">
            <a href="#"><img class="footer_logo shrink" ngbTooltip="#SoftwareAsAFuture" src="../../../assets/img/dastfooter.png"></a>
            <div class="spacing-mini"></div>
            <a href="https://twitter.com/dastsoftware" target="_blank"><button class="btn btn-sm btn-light m-1"><i class="fab fa-twitter"></i></button></a>
            <a href="https://instagram.com/dast.software" target="_blank"><button class="btn btn-sm btn-light m-1"><i class="fab fa-instagram"></i></button></a>
            <a href="https://www.linkedin.com/company/dastsoftware" target="_blank"><button class="btn btn-sm btn-light m-1"><i class="fab fa-linkedin"></i></button></a>
            <a href="https://www.tiktok.com/@dast.software" target="_blank"><button class="btn btn-sm btn-light m-1"><i class="fab fa-tiktok"></i></button></a>
            <a href="https://www.youtube.com/channel/UCO8WDhIQITuhqnIEfAm6YFw" target="_blank"><button class="btn btn-sm btn-light m-1"><i class="fab fa-youtube"></i></button></a>
            <p class="text-muted">{{translate.translate("about_foot")}}</p>
            <hr class="show-mobile">
        </div>
        <div class="col-sm text-right mobile-center ml-auto mr-auto mt-auto mb-auto">
            <h5 class="text-muted mobile-center">Dast</h5>
            <div class="spacing-mini"></div>
            <a class="mobile-center" routerLink="/job"><p>{{translate.translate("job")}}</p></a>
            <a class="mobile-center" routerLink="/about"><p>{{translate.translate("about")}}</p></a>
            <a class="mobile-center" routerLink="/contact"><p>{{translate.translate("contact")}}</p></a>
        </div>
        <div class="col-sm text-right mobile-center mt-auto mb-auto">
            <hr class="show-mobile">
            <h5 class="text-muted mobile-center">Legal</h5>
            <div class="spacing-mini"></div>
            <a class="mobile-center" routerLink="/legal/terms"><p>{{translate.translate("terms")}}</p></a>
            <a class="mobile-center" routerLink="/legal/privacy-policy"><p>{{translate.translate("privacy-policy")}}</p></a>
            <a class="mobile-center" routerLink="/legal/cookies-policy"><p>{{translate.translate("cookies-policy")}}</p></a>
        </div>
    </div><hr class="separator">
    <div class="row">
        <div class="col text-center">
            Dast &copy; {{date}}
        </div>
    </div>
    <div class="spacing-mini"></div>
</div>