<div *ngIf="translate.getLang()=='en'">
    <h1>Sorry, we´re still working on this section</h1>
    <p>Meanwhile, We´re ablle to tell you that we won´t request any type of degree<br>
    <p> Dast Jobs, is a platform to work as a freelancer for Dast. Will be available soon.</p>
    <h3>What will we require?</h3>
    <ul>
        <li>Know how to communicate in English</li>
        <li>Teamwork Skills</li>
        <li>Be able to adapt in any situation (Trello WorkFlow, Github, etc)</li>
        <li>Respect for stipulated deadlines</li>
        <li>Your Origin country mustn't be one of the listed by OFAC, view the documentation <a class="text-primary"
                href="https://stripe.com/restricted-businesses" target="_blank">Stripe</a></li>
    </ul>
    <h3>What advantages you will have?</h3>
    <ul>
        <li>You´ll be able to work from anywhere in the world</li>
        <li>Payment flexibility (Fiat or Crypto)</li>
        <li>No schedules (Only stipulated deadlines)</li>
        <li>Job system</li>
    </ul>
    <h3>How does our job system work?</h3>
    <p>You´re winning better offers as the same time you´re working, for a better work the offer will be higher, and the
        salary too.</p>
</div>

<div *ngIf="translate.getLang()=='es'">
    <h1>Lo sentimos, estamos trabajando en esta sección</h1>
    <p>Mientras tanto, podemos adelantarte que no solicitaremos ningún tipo de titulación<br>
        Muy pronto estará disponible Dast Jobs, una plataforma para trabajar como freelancer para Dast.</p>
    <h3>¿Qué exigiremos?</h3>
    <ul>
        <li>Saber comunicarte por texto en inglés</li>
        <li>Capacidad para trabajar en equipo</li>
        <li>Poder adaptarte ante cualquier situación (Estilo Trello, Github, etc)</li>
        <li>Compromiso con las fechas exigidas</li>
        <li>Tu país de procedencia no debe ser uno listado por la OFAC, incluyendo el listado de <a class="text-primary"
                href="https://stripe.com/es/restricted-businesses" target="_blank">Stripe</a></li>
    </ul>
    <h3>¿Qué ventajas tendrá?</h3>
    <ul>
        <li>Puedes trabajar desde cualquier lugar del mundo</li>
        <li>Flexibilidad de pago (Fiat o Criptomonedas)</li>
        <li>Sin horarios (Sólo fechas de entrega)</li>
        <li>Sistema de trabajos</li>
    </ul>
    <h3>¿Cómo funciona el sistema de trabajos?</h3>
    <p>Vas ganando mejores ofertas a medida que haces bien los trabajos, cuanto mejor es la oferta, mayor será el
        salario.</p>
</div>