import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FunctionsService } from 'src/app/services/functions.service';
import { TranslateService } from 'src/app/services/translate.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  public preloader: boolean = false;
  public page: string;
  public title: string = "";
  public lang: string;

  constructor(public func: FunctionsService, public translate: TranslateService, private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.page = val.url.split("?")[0];
      }
    });
  }

  ngOnInit(): void {
  }

  getCorrectString() {
    let result:string;
    if (this.page.includes("/legal")) {
      result = this.translate.translate(this.page.replace("/legal/", ""));
    } else {
      result = this.translate.translate(this.page.replace("/", ""));
    }
    return result;
  }

}
