<a routerLink="/ds_admin/post/create"><button class="btn btn-primary"><i class="fas fa-plus"></i> Create post</button></a><br><br>
<table datatable class="table table-hover">
    <thead>
        <tr>
            <th>#</th>
            <th>Title</th>
            <th>Author</th>
            <th>Updated at</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of posts">
            <td style="vertical-align:middle;{{p.status_slug=='DRAFT'?'background-color: rgba(23,23,23,0.2);':''}}">{{p.id}}</td>
            <td style="vertical-align:middle;{{p.status_slug=='DRAFT'?'background-color: rgba(23,23,23,0.2);':''}}">{{p.title}}</td>
            <td style="vertical-align:middle;{{p.status_slug=='DRAFT'?'background-color: rgba(23,23,23,0.2);':''}}">{{p.author}}</td>
            <td style="vertical-align:middle;{{p.status_slug=='DRAFT'?'background-color: rgba(23,23,23,0.2);':''}}">{{getDate(p.updated_at)}}</td>
            <td style="vertical-align:middle;{{p.status_slug=='DRAFT'?'background-color: rgba(23,23,23,0.2);':''}}"><a routerLink="/ds_admin/post/edit/{{p.id}}"><button class="btn btn-light"><i class="fas fa-edit"></i></button></a><button (click)="deleteElement(p.id)" style="margin-left:2%;" class="btn btn-light"><i class="fas fa-trash"></i></button></td>
        </tr>
    </tbody>
</table>