<div *ngIf="!contains('login')" class="container-fluid">
    <div class="row min-vh-100">
        <div class="col-2 bg-light">
            <div class="sticky-top m-2">
                <center style="margin-bottom:4%;margin-top:4%;"><a routerLink="/"><img class="shrink"
                            style='width:100px' src='/assets/img/blacklogo.png'></a></center>
                <div *ngFor="let m of menu">
                    <a routerLink="{{m.url}}">
                        <button style='height:100px;margin-top:4%;' class="btn w-100">
                            <div class="row h5">
                                <div class="col">
                                    <i class="fas fa-{{m.icon}} color-first"></i>
                                </div>
                                <div class="col mt-auto mb-auto color-first">
                                    {{m.title}}
                                </div>
                            </div>
                        </button>
                    </a>
                </div>
                <center style="margin-top:4%;"><small>Dast &copy; {{date}}</small></center>
            </div>
        </div>
        <div class="col">
            <br>
            <h3>{{title}}</h3>
            <hr>
            <app-dashboard *ngIf="contains('dashboard')"></app-dashboard>
            <app-post-list *ngIf="contains('posts')"></app-post-list>
            <app-edit-post *ngIf="contains('post/edit')"></app-edit-post>
            <app-create-post *ngIf="contains('post/create')"></app-create-post>
        </div>
    </div>
</div>

<div class="min-vh-100 bg-light d-flex" *ngIf="contains('login')">
    <button (click)="signInWithGoogle()" style='height:80px;' class="btn d-flex align-items-center mt-auto mb-auto ml-auto mr-auto text-center">
        <div class="row h5 text-center">
            <div class="col-2 ml-auto mr-auto mt-auto mb-auto">
                <i class="fab fa-google color-first"></i>
            </div>
            <div class="col mt-auto mb-auto ml-auto mr-auto color-first">
                Sign in
            </div>
        </div>
    </button>
</div>
<app-logout *ngIf="contains('logout')"></app-logout>